import React from 'react';
import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.form`
  /* width: 530px; */
  /* height: 568px;   */
  width: 854px;

  border-radius: 16px;
  border: 1px #dedede solid;
  background-color: white;
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 11;
  background: rgba(0, 0, 0, 0.5);
`;

export const Required = styled.div`
  color: #dd0404;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 5px;
  border: 1px #dedede solid;

  cursor: pointer;
`;

export const Label = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
`;

export const Space = styled.div``;

export const Box = styled.div`
  padding: 16px 0 16px 24px;
`;

export const Header = styled.div`
  padding: 16px 24px;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const CheckOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 20px;
  margin-top: 16px;
`;

export const Content = styled.div`
  display: flex;
`;

export const PatientInfo = styled.div`
  width: 50%;
  border-right: 1px #f8f8f8 solid;
  padding: 24px;
`;

export const OtherProfessional = styled.div`
  width: 50%;
  padding: 24px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 24px;
`;

export const InlineInputs = styled.div`
  display: flex;
  gap: 18px;
  align-items: first baseline;
`;

export const Title = styled.div`
  color: #222529;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
`;

export const SubTitle = styled.div`
  color: #62666c;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: #dedede;
`;

export const InformationTitle = styled.div`
  color: #636d77;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InputContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxText = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;

  margin-left: 8px;
`;

export const selectStyles = (error: boolean) => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    border: error ? '1px solid #dd0404' : '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 2,
  }),
});
