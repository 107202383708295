/* eslint-disable react/react-in-jsx-scope */
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Icon, Typography } from '@wisecare-tech/design-system-web';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconDuties, IconPerson } from '~/presentation/base/icons';
import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import DutyRequesterPage from '~/presentation/pages/DutyRequester';
import { History } from '~/main/routes';
import { iStore } from '~/domain/interfaces/models';
import { JoinOnCallBody } from '~/presentation/components/modalRequestConsultation';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { closeModal } from '~/utils/closeModal';
import { makeReduxGetDuties } from '../../usecases/duty/GetDuties';
import { makeReduxActiveMessage } from '../../usecases/message/Update';
import {
  ButtonToOpenSideSheet,
  Container,
  OnBackButton,
  TitleBack,
} from './styles';
import { makeRemoteLeftRequesterOnCall } from '../../usecases/onCall/LeftRequesterOnCallFactory';
import { makeRemoteJoinOnCall } from '../../usecases/duty/JoinOnCall';
import { makeRemoteRemovedOnCallRequester } from '../../usecases/onCallRequester/RemovedOnCallRequesterFactory';

const DutyRequesterFactory: React.FC = () => {
  const auth = useSelector((store: iStore) => store.auth.info);
  const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);
  const professional = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const state = useLocation<{
    specialty: string;
    id: number;
    formValues: JoinOnCallBody;
  }>();

  return (
    <Container>
      <Menu />
      <OnBackButton>
        <TitleBack
          onClick={() => {
            makeReduxActiveMessage().active({
              active: 'twoButtonModal',
              actionOk: () => {
                if (professional?.id)
                  makeRemoteRemovedOnCallRequester()
                    .removed({
                      requesterId: professional.id,
                    })
                    .catch(() => {
                      console.log('error');
                    });
                History.getHistory().goBack();
                closeModal();
              },
              actionCancel: closeModal,
              data: {
                specialty: state?.state?.specialty,
                type: 'requester',
                title: 'Confirme se quer sair do plantão',
                message:
                  'Se sair agora, os profissionais não poderão atender à sua solicitação e você não receberá notificações sobre suas solicitações. Tem certeza que deseja sair agora?',
                textPrimaryButton: 'Sim, quero sair',
                textSecondaryButton: 'Permanecer aqui',
                variant: 'CANCEL',
                exit: true,
              },
            });
          }}
        >
          <Icon name="chevron_left" color="blue-7" />
          <Typography variant="b3_14medium" style={{ color: '#0565D9' }}>
            Voltar para unidade de saúde
          </Typography>
        </TitleBack>
        <div>
          <Typography variant="b3_14medium" style={{ color: '#656A6E' }}>
            Plantão Virtual
          </Typography>
        </div>
        <div />
      </OnBackButton>

      <DutyRequesterPage onCallId={Number(state?.state?.id)} />
    </Container>
  );
};

const Button: React.FC<{ onCallId: number }> = ({ onCallId }) => {
  const handleOpenDrawer = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.professionalsOnDuty,
      data: {
        onCallId,
      },
    });
  }, [onCallId]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <ButtonToOpenSideSheet type="button" onClick={handleOpenDrawer}>
      <IconDuties />
    </ButtonToOpenSideSheet>
  );
};

export default DutyRequesterFactory;
