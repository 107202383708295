/* eslint-disable react/jsx-curly-brace-presence */
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { AlertIconSuccess } from '~/presentation/base/icons';

import { translator } from '../i18n'; // TODO: check this component translation

import {
  Body,
  ConsultInfoContainer,
  ConsultInfoMessage,
  Container,
  ContainerModal,
  Footer,
  Header,
  ScrollContainer,
  TextBold,
  TextBoldTitle,
  TextNormalSubtitle,
  NewConsultInfo,
  InfoContainer,
  InfoRow,
  Label,
  Value,
} from './styles/styles';
import ConsultDetailFragment from './ConsultDetailFragment';
import { iStore } from '~/domain/interfaces/models';
import { iDutyRequesters } from '~/domain/interfaces/models/Duty';
import { makeRemoteRefuseAppointmentOnCall } from '~/main/factories/usecases/onCall/RefuseAppointmentOnCallFactory';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { IconButton, Button } from '@wisecare-tech/design-system-web';
import { calculateAgeAndMonths } from '~/utils/calculateAgeAndMonths';

interface propsModalNewAvailableInterconsult {
  message: iMessage;
}

const ModalNewAvailableInterconsult: React.FC<
  propsModalNewAvailableInterconsult
> = ({ message }) => {
  const [professionalName, setProfessionalName] = useState('Não Consta');
  const [professionalSpecialty, setProfessionalSpecialty] =
    useState('Não Consta');
  const [patient, setPatient] = useState<iOnCallRequest['records'][0]>();
  const { records } = useSelector((state: iStore) => state.onCallRequest);

  const { active, actionOk, actionCancel, data } = message;

  useEffect(() => {
    if (data) {
      const requester = records?.find(
        item => Number(item.id) === Number(data.requestId),
      );

      if (data.type === 'SPECIALIST' && requester) {
        setProfessionalName(requester.requester.fullname);
      } else if (data.type === 'REQUESTER') {
        setProfessionalName(data?.specialist);
      }

      setPatient(requester);
      setProfessionalSpecialty(data.onCall?.descr);
    }
  }, [data, records]);

  const handleCancel = () => {
    if (data?.type === 'SPECIALIST')
      makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
        available: false,
      });

    /* makeRemoteRefuseAppointmentOnCall().refuseAppointment({
        onCallId: Number(data?.onCall?.id),
        requesterId: Number(data?.requester),
        specialistId: Number(data?.specialist),
      }); */
    actionCancel?.();
  };

  const onSubmit = () => {
    actionOk?.();
  };

  const renderTitle = useMemo(() => {
    return data.type === 'SPECIALIST'
      ? 'Interconsulta disponível!'
      : 'Seu pedido de interconsulta foi aceito!';
  }, [data]);

  const patientSex = useMemo(() => {
    if (!patient?.consultant?.sex) {
      return 'Não Informado';
    }
    return patient?.consultant.sex === 'M' ? 'Masculino' : 'Feminino';
  }, [patient]);

  const renderSubtitle = useMemo(() => {
    return data.type === 'SPECIALIST'
      ? `O profissional ${professionalName} já se encontra disponível para discutir o caso do paciente abaixo`
      : `O profissional ${professionalName}, na especialidade de ${professionalSpecialty}, aceitou sua solicitação para discutir o caso do paciente abaixo:`;
  }, [professionalName, professionalSpecialty, data]);

  const msgName = MessageOptions.newAvailableInterconsult;

  return (
    <>
      {msgName === active && (
        <Suspense fallback={<div>Loading...</div>}>
          <ContainerModal>
            <Container>
              <Header>
                <AlertIconSuccess />
                <IconButton variant="primary" icon="close" />
              </Header>
              <Body>
                <ConsultInfoMessage>
                  <TextBoldTitle>{translator(renderTitle)}</TextBoldTitle>
                  <TextNormalSubtitle>
                    O profissional aceitou sua solicitação para discutir o caso
                    do paciente abaixo:
                  </TextNormalSubtitle>
                </ConsultInfoMessage>
                <ConsultInfoContainer>
                  <InfoContainer>
                    <div style={{ marginBottom: 16 }}>
                      <InfoRow>
                        <Label>Profissional:</Label>
                        <Value>{professionalName}</Value>
                      </InfoRow>
                      <InfoRow>
                        <Label>Especialidade:</Label>
                        <Value>
                          {professionalSpecialty ?? 'Não informado'}
                        </Value>
                      </InfoRow>
                    </div>
                    <InfoRow>
                      <Label>Paciente:</Label>
                      <Value>
                        {patient?.consultant.fullname ?? 'Não informado'}
                      </Value>
                    </InfoRow>
                    <InfoRow>
                      <Label>Sexo:</Label>
                      <Value>{patientSex ?? 'Não informado'}</Value>
                    </InfoRow>
                    <InfoRow>
                      <Label>Data de nascimento:</Label>
                      <Value>{`${patient?.consultant?.birthdate ? calculateAgeAndMonths(patient.consultant.birthdate) : 'Não informado'}`}</Value>
                    </InfoRow>
                    {patient?.consultant?.docType && (
                      <InfoRow>
                        <Label>{patient?.consultant.docType}</Label>
                        <Value>{patient?.consultant.docValue}</Value>
                      </InfoRow>
                    )}
                  </InfoContainer>
                </ConsultInfoContainer>
              </Body>
              <Footer>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancelar solicitação
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                  Entrar na interconsulta
                </Button>
              </Footer>
            </Container>
          </ContainerModal>
        </Suspense>
      )}
    </>
  );
};

export default ModalNewAvailableInterconsult;
