import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ReactivateOnCallRequest as UsecaseRemoteReactivateOnCallRequest } from '~/domain/usecases/onCallRequest/remote';

import { RemoteReactivateOnCallRequest } from '~/data/repository/onCallRequest';

/**
 * send request via API.
 */
export const makeRemoteReactivateOnCallRequest =
  (): UsecaseRemoteReactivateOnCallRequest =>
    new RemoteReactivateOnCallRequest(makeApiUrl('/oncall'), makeHttpClient());
