import { object, number, string, boolean, array } from 'zod';
import { validateCpf } from '~/utils/validCpf';

export const createEmergencyConsultationSchema = object({
  consultantName: string().nonempty({ message: 'Campo obrigatório' }),
  sex: string().nonempty({ message: 'Campo obrigatório' }),
  birthDate: string()
    .nonempty({ message: 'Campo obrigatório' })
    .refine(date => /^\d{2}\/\d{2}\/\d{4}$/.test(date), {
      message: 'Data de nascimento inválida',
    }),
  docType: string().nonempty({ message: 'Campo obrigatório' }),
  docNumber: string().nonempty({ message: 'Campo obrigatório' }),
  professionalName: string().optional(),
  state: string().nonempty({ message: 'Campo obrigatório' }),
  city: string().nonempty({ message: 'Campo obrigatório' }),
  healthUnit: string().nonempty({ message: 'Campo obrigatório' }),
  id: number().optional(),
})
  .refine(
    data => {
      switch (data.docType) {
        case 'CPF':
          return validateCpf(data.docNumber);
        case 'DNV':
          return /^\d{11}$/.test(data?.docNumber);
        case 'CNS':
          return /^\d{15}$/.test(data?.docNumber);
        case 'RG':
          return true;
        default:
          return false;
      }
    },
    {
      message: 'Documento inválido',
      path: ['docNumber'],
    },
  )
  .refine(
    data => {
      return data.id ? true : !!data.professionalName;
    },
    {
      message: 'Campo obrigatório',
      path: ['professionalName'],
    },
  );
