import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { OrgUnitList as UseCaseRemoteOrgUnitList } from '~/domain/usecases/orgUnit/remote';
import { OrgUnitList as UsecaseReduxGetAllOrgUnitList } from '~/domain/usecases/orgUnit/redux';

import { RemoteOrgUnitList } from '~/data/repository/orgUnit';
import { ReduxOrgUnitList } from '~/data/store/reducer/orgUnit/usecases';

export const makeRemoteOrgUnitList = (): UseCaseRemoteOrgUnitList =>
  new RemoteOrgUnitList(makeApiUrl('/orgUnits/list/acl'), makeHttpClient());

export const makeReduxGetAllOrgUnitList = (): UsecaseReduxGetAllOrgUnitList =>
  new ReduxOrgUnitList();
