import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { GetCertificates } from '~/domain/usecases/deamon/remote/GetCertificates';

import { UnexpectedError } from '~/domain/errors';

export class RemoteGetCertificates implements GetCertificates {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetCertificates.Model>;

  constructor(url: string, httpClient: HttpClient<GetCertificates.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getCertificates = async (): Promise<GetCertificates.Model> => {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'get',
      headers: {
        'Access-Control-Allow-Private-Network': 'true',
        'Access-Control-Request-Private-Network': 'true',
      },
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body;
      default:
        throw new UnexpectedError();
    }
  };
}
