import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Steps } from '@wisecare-tech/design-system-web';
import { format, parse } from 'date-fns';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteCreateDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/CreateDiagnosisReportFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { History } from '~/main/routes';
import { closeModal } from '~/utils/closeModal';
import {
  fileSchema,
  patientSchema,
  requestSchema,
  responsibleSchema,
} from '~/validation/validators/diagnosis/CreateDiagnosisValidator';
import { Files, Patient, Request, Responsible } from '.';
import { AlertMessage } from '../messages/AlertMessage';
import {
  ButtonsContainerPrimary,
  ButtonsContainerSecondary,
  Footer,
  FormContent,
  StepContent,
} from './styles/StyledCreateReport';

interface StepsProps {
  label: string;
  completed: boolean;
  active: boolean;
}

export type FormData = {
  state: string;
  county: string;
  healthUnit: string;
  responsePriority: string;
  description: string;
  modality: string;
  profession: string;
  specialty: string;
  profissional: string;
  fullName: string;
  isDisabled: boolean;
  sex: string;
  birthDate: string;
  regType: string;
  regValue: string;
  motherName: string;
  phone: string;
  patientDescription: string;
  files: Array<
    { id: number; filename: string; size?: number } & { examType?: number }
  >;
  specialtySelected: boolean;
  professionalSelected: boolean;
  consultant: number;
  orgUnit?: number;
};

const schemas = [requestSchema, responsibleSchema, patientSchema, fileSchema];

const CreateReport = () => {
  const [crement, setCrement] = useState<number>(1);
  const [steps, setSteps] = useState<StepsProps[]>([
    {
      label: 'Solicitação',
      active: true,
      completed: false,
    },
    {
      label: 'Responsável',
      active: false,
      completed: false,
    },
    {
      label: 'Paciente',
      active: false,
      completed: false,
    },
    {
      label: 'Arquivos',
      active: false,
      completed: false,
    },
  ]);

  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const methods = useForm<FormData>({
    defaultValues: {
      description: '',
      state: 'PB',
      county: '',
      healthUnit: '',
      modality: '',
      responsePriority: '',
      specialtySelected: true,
      professionalSelected: false,
      isDisabled: false,
      profession: '',
      specialty: '',
      profissional: '',
      birthDate: '',
      regType: '',
      regValue: '',
      fullName: '',
      motherName: '',
      phone: '',
      files: [],
      sex: '',
    },
    resolver: zodResolver(schemas[crement - 1]) as any,
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    watch,
    errors,
    formState: { isValid },
  } = methods;

  const onSubmit: SubmitHandler<FormData> = data => {
    nextStep();
  };

  const nextStep = () => {
    const currentStep = steps?.findIndex(step => step.active);

    if (currentStep === steps?.length - 1) return;

    const updatedSteps = steps.map((step, index) => {
      if (index === currentStep) {
        return { ...step, active: false, completed: true };
      }
      if (index === currentStep + 1) {
        return { ...step, active: true, completed: false };
      }
      return step;
    });

    setSteps(updatedSteps);
    setCrement(prev => prev + 1);
  };

  const backStep = () => {
    const currentStep = steps?.findIndex(step => step.active);

    if (currentStep === 0) return;

    const updatedSteps = steps.map((step, index) => {
      if (index === currentStep) {
        return { ...step, active: false, completed: false };
      }
      if (index === currentStep - 1) {
        return { ...step, active: true, completed: false };
      }
      return step;
    });

    setSteps(updatedSteps);
    setCrement(prev => prev - 1);
  };

  const sketchDiagnosisReport = () => {
    const parsedDate = parse(watch('birthDate'), 'dd/MM/yyyy', new Date());

    const formattedDate = format(parsedDate, 'yyyy-MM-dd');

    const sendFiles = watch('files').map(file => {
      return {
        id: file?.id,
        examType: file?.examType,
      };
    });

    makeRemoteCreateDiagnosisReport()
      .create({
        org: Number(orgId),
        orgUnit: Number(watch('orgUnit')) ?? undefined,
        healthUnit: Number(watch('healthUnit')),
        modality: Number(watch('modality')),
        priority: watch('responsePriority'),
        description: watch('description'),
        specialty: Number(watch('specialty')),
        specialist: watch('profissional')
          ? Number(watch('profissional'))
          : undefined,
        consultantExternal: watch('consultant')
          ? undefined
          : {
              fullname: watch('fullName'),
              birthdate: formattedDate,
              docType: watch('regType'),
              docValue: watch('regValue').replace(/\D/g, ''),
              mothername: watch('motherName'),
              phone: watch('phone').replace(/\D/g, ''),
              sex: watch('sex'),
            },
        consultant: watch('consultant') ?? undefined,
        status: 'EDITING',
        consultantInfo: watch('patientDescription'),
        files: sendFiles ?? undefined,
      })
      .then(() => {
        AlertMessage({
          message: 'Rascunho salvo com sucesso',
          type: 'success',
        });
        History.getHistory().push('/diagnosis');
        methods.reset();
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao salvar rascunho',
          type: 'danger',
        });
      });
  };

  const createDiagnosisReport = () => {
    const parsedDate = parse(watch('birthDate'), 'dd/MM/yyyy', new Date());

    const formattedDate = format(parsedDate, 'yyyy-MM-dd');

    const sendFiles = watch('files').map(file => {
      return {
        id: file?.id,
        examType: file?.examType,
      };
    });

    makeRemoteCreateDiagnosisReport()
      .create({
        org: Number(orgId),
        orgUnit: Number(watch('orgUnit')) ?? undefined,
        healthUnit: Number(watch('healthUnit')),
        modality: Number(watch('modality')),
        priority: watch('responsePriority'),
        description: watch('description'),
        specialty: Number(watch('specialty')),
        specialist: watch('profissional')
          ? Number(watch('profissional'))
          : undefined,
        consultantExternal: watch('consultant')
          ? undefined
          : {
              fullname: watch('fullName'),
              birthdate: formattedDate,
              docType: watch('regType'),
              docValue: watch('regValue').replace(/\D/g, ''),
              mothername: watch('motherName'),
              phone: watch('phone').replace(/\D/g, ''),
              sex: watch('sex'),
            },
        consultant: watch('consultant') ?? undefined,
        status: 'SUBMITTED',
        consultantInfo: watch('patientDescription'),
        files: sendFiles ?? undefined,
      })
      .then(() => {
        makeReduxActiveMessage().active({
          active: 'reportSuccess',
          data: {
            professionalId: watch('profissional') ?? undefined,
            profession: watch('profession'),
            specialty: watch('specialty'),
            priority: watch('responsePriority'),
          },
          actionOk: () => {
            History.getHistory().push('/diagnosis');
            methods.reset();
            closeModal();
          },
          actionCancel: () => {
            closeModal();
          },
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao criar telelaudo',
          type: 'danger',
        });
      });
  };

  console.log('##watch', watch());

  return (
    <FormProvider {...methods}>
      <form>
        <FormContent>
          <Steps steps={steps} />
        </FormContent>
        <StepContent>
          {crement === 1 && <Request />}
          {crement === 2 && <Responsible />}
          {crement === 3 && <Patient />}
          {crement === 4 && <Files />}
        </StepContent>
        <Footer>
          <ButtonsContainerSecondary>
            <Button
              variant="outlinedDestructive"
              type="button"
              size="md"
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'cancelReport',
                  actionOk: () => {
                    closeModal();
                  },
                  actionCancel: () => {
                    methods.reset();
                    History.getHistory().push('/diagnosis');
                    closeModal();
                  },
                });
              }}
            >
              Cancelar
            </Button>
            {crement === 4 && (
              <Button
                variant="text"
                type="button"
                onClick={() => {
                  makeReduxActiveMessage().active({
                    active: 'sketchReport',
                    actionOk: () => {
                      sketchDiagnosisReport();
                      closeModal();
                    },
                    actionCancel: () => {
                      closeModal();
                    },
                  });
                }}
              >
                Salvar como rascunho
              </Button>
            )}
          </ButtonsContainerSecondary>

          <ButtonsContainerPrimary>
            {crement > 1 && (
              <Button
                variant="outlined"
                size="md"
                onClick={e => {
                  e.preventDefault();
                  backStep();
                }}
              >
                Voltar
              </Button>
            )}
            <Button
              variant="primary"
              size="md"
              type="submit"
              onClick={e => {
                if (crement === 4) {
                  createDiagnosisReport();
                }
                e.preventDefault();
                handleSubmit(onSubmit)();
              }}
            >
              {crement === 4 ? 'Concluir' : 'Avançar'}
            </Button>
          </ButtonsContainerPrimary>
        </Footer>
      </form>
    </FormProvider>
  );
};

export default CreateReport;
