import { DownloadRequestFileById } from '~/domain/usecases/onCallRequestFile/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  Conflict,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteDownloadByIdRequestFile implements DownloadRequestFileById {
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadRequestFileById.Model>;

  constructor(
    url: string,
    httClient: HttpClient<DownloadRequestFileById.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (
    params: DownloadRequestFileById.Params,
  ): Promise<DownloadRequestFileById.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.onCallId}/requests/${params.requestId}/files/${params.fileId}/DOWNLOAD`,
      responseType: 'blob',
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
