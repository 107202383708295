import { defaultTheme } from '~/presentation/base/themes';
import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const TextAreaForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

interface iSelectLabel {
  required?: boolean;
}
export const SelectLabel = styled.span<iSelectLabel>`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;

  ${({ required }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: #dd0404;
      }
    `}
`;

export const Container = styled.div`
  width: 1060px;
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 16px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid #dedede;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Main = styled.main`
  width: 100%;
  display: flex;
`;

export const SectionLeft = styled.section`
  display: flex;
  padding: 24px;
  width: 50%;
  flex-direction: column;
  border-right: 1px solid #dedede;
`;

export const SectionRight = styled.section`
  display: flex;
  width: 50%;
  padding: 24px;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InlineForms = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-top: 24px;
`;

export const Footer = styled.footer`
  border-top: 1px solid #dedede;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const selectStyles = () => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    border: '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
    fontSize: '14px',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuList: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  option: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuPortal: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
});
