/* eslint-disable spaced-comment */
import React, { useEffect, useRef, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import WiseAPI from 'wise-api';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { makeRemoteStartAppointment } from '~/main/factories/usecases/appointment/StartAppointment';
import { makeReduxLeftWaitingRoom } from '~/main/factories/usecases/waitingRoom/LeftWaitingRoomFactory';

import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import { AppointmentTypes } from '~/domain/interfaces/redux/appointment/types';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { History } from '~/main/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { V4hSpin } from '~/presentation/components/spin';
import { Sidebar } from '../../components/sidebar';
import { Container, MeetDiv } from './styledConf';
import { makeRemoteUnavailableOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/UnavailableOnCallSpecialistFactory';
import { makeRemoteUnavailableOnCallRequester } from '~/main/factories/usecases/onCallRequester/UnavailableOnCallRequesterFactory';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const confLogo =
  'https://minio.v4h.cloud/atendimento/v4h_atendimento_logo_dark.png';

export interface ParamsState {
  isMicOn: boolean;
  isCamOn: boolean;
  appointmentId: string;
  waitingRoomUserId: string;
  guestData?: iGuestData;
  onCall?: {
    sessionName: string;
    specialist?: number;
    requester?: number;
    name?: string;
    id?: number;
  };
  healthUnit?: {
    id: number;
    cnes: string;
    name: string;
    administration: string;
    city: {
      id: number;
      code: string;
      codeFull: string;
      name: string;
    };
  };
  outcomeByRequester?: boolean;
}

export const Conf: React.FC = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const {
    isMicOn,
    isCamOn,
    appointmentId,
    waitingRoomUserId,
    guestData = null,
    onCall,
    healthUnit,
    outcomeByRequester,
  } = useLocation<ParamsState>().state;
  const auth = useSelector((store: iStore) => store.auth.info);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const timeoutMeet: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    if (guestData) {
      const start = async (): Promise<void> => {
        try {
          const { roomName, sessionName, sessiontoken, link } =
            await makeRemoteStartAppointment().start({
              appointmentId: Number(guestData?.appointment?.id),
            });

          const v4h = await WiseAPI({
            baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
            domain: `${window.config.connection.confUrl}`,
          });

          await v4h.session.startConference(sessionName, {
            parentNode: document.getElementById('meet'),
            userInfo: {
              displayName:
                guestData?.guest?.identification?.fullName || 'Convidado',
            },
            // logo: confLogo,
            startWithAudioMuted: !isMicOn,
            startWithVideoMuted: !isCamOn,
            buttons: [
              'microphone',
              'camera',
              'desktop',
              'fullscreen',
              'hangup',
              'profile',
              'info',
              'chat',
              'livestreaming',
              'sharedvideo',
              'settings',
              'raisehand',
              'videoquality',
              'filmstrip',
              'invite',
              'feedback',
              'stats',
              'shortcuts',
              'tileview',
              'download',
              'help',
            ],
            onClose: () => History.push('/guest'),
            shareLink: link?.guest ?? undefined,
          });

          setLoading(false);
        } catch (error) {
          setLoading(false);
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possível entrar na conferência, tente novamente',
            }),
            type: 'danger',
          });
          History.getHistory().push('/guest/preconference', {
            conference: guestData,
          });
        }
      };

      start();

      // timeoutMeet.current = setTimeout(() => {
      //   const meetChildNodesCount =
      //     document.getElementById('meet')?.childNodes.length;

      //   if (!meetChildNodesCount) start();
      // }, 2000);
    } else if (role === 'CON') {
      const start = async (): Promise<void> => {
        try {
          const { roomName, sessionName, sessiontoken, link } =
            await makeRemoteStartAppointment().start({
              appointmentId: Number(appointmentId),
            });

          const v4h = await WiseAPI({
            baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
            domain: `${window.config.connection.confUrl}`,
          });

          await v4h.session.startConference(sessionName, {
            parentNode: document.getElementById('meet'),
            userInfo: {
              displayName: `${auth.user?.firstName} ${auth.user?.lastName}`,
            },
            // logo: confLogo,
            startWithAudioMuted: !isMicOn,
            startWithVideoMuted: !isCamOn,
            buttons: [
              'microphone',
              'camera',
              'desktop',
              'fullscreen',
              'hangup',
              'profile',
              'info',
              'chat',
              'livestreaming',
              'sharedvideo',
              'settings',
              'raisehand',
              'videoquality',
              'filmstrip',
              'invite',
              'feedback',
              'stats',
              'shortcuts',
              'tileview',
              'download',
              'help',
              'libras',
            ],
            onClose: () => History.getHistory().replace('/appointment'),
            shareLink: link?.guest ?? undefined,
          });

          setLoading(false);
        } catch (error) {
          setLoading(false);
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possível entrar na conferência, tente novamente',
            }),
            type: 'danger',
          });
          History.getHistory().replace('/appointment');
        }
      };

      start();

      // timeoutMeet.current = setTimeout(() => {
      //   const meetChildNodesCount =
      //     document.getElementById('meet')?.childNodes.length;

      //   if (!meetChildNodesCount) start();
      // }, 2000);
    } else {
      const start = async (): Promise<void> => {
        try {
          const { sessionName, link } = !onCall?.sessionName?.length
            ? await makeRemoteStartAppointment().start({
                appointmentId: Number(appointmentId),
              })
            : { sessionName: onCall?.sessionName, link: null };

          const v4h = await WiseAPI({
            baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
            domain: `${window.config.connection.confUrl}`,
          });

          console.log(
            '>>> startConference',
            sessionName,
            `${auth.user?.firstName} ${auth.user?.lastName}`,
            onCall,
            healthUnit,
          );

          if (sessionName) {
            await v4h.session.startConference(sessionName, {
              parentNode: document.getElementById('meet'),
              userInfo: {
                displayName: `${auth.user?.firstName} ${auth.user?.lastName}`,
              },
              // logo: confLogo,
              startWithAudioMuted: !isMicOn,
              startWithVideoMuted: !isCamOn,
              buttons: [
                'microphone',
                'camera',
                'desktop',
                'fullscreen',
                'hangup',
                'profile',
                'info',
                'chat',
                'recording',
                'livestreaming',
                'sharedvideo',
                'settings',
                'raisehand',
                'videoquality',
                'filmstrip',
                'invite',
                'feedback',
                'stats',
                'shortcuts',
                'tileview',
                'download',
                'help',
                'mute-everyone',
                'libras',
              ],
              onParticipantLeft: () => {
                if (
                  !onCall?.specialist &&
                  onCall?.sessionName &&
                  onCall?.requester &&
                  !outcomeByRequester
                ) {
                  makeRemoteUnavailableOnCallRequester().unavailable({
                    requesterId: Number(onCall?.requester),
                  });

                  History.getHistory().push('/duty/me', {
                    specialty: 'cardiologia',
                    healthUnitId: healthUnit?.id,
                    healthUnitName: healthUnit?.name,
                    cnes: healthUnit?.cnes,
                    city: healthUnit?.city,
                  });
                }
              },
              onClose: () => {
                if (onCall?.specialist) {
                  makeRemoteUnavailableOnCallSpecialist()
                    .unavailable({
                      onCallId: Number(onCall?.id),
                      specialistId: Number(onCall?.specialist),
                    })
                    .then(() => {
                      makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                        available: false,
                      });
                    })
                    .catch(err => {
                      console.log('Error pausing: ', err);
                    });
                  History.getHistory().replace(`/duty/${onCall?.id}`, {
                    specialty: onCall?.name,
                    id: onCall?.id,
                    leaveConf: true,
                  });

                  storeDev.dispatch({
                    type: AppointmentTypes.STOP,
                    payload: {
                      id: Number(appointmentId),
                      onCall: {
                        id: onCall?.id,
                        specialty: onCall?.name,
                        leaveConf: true,
                      },
                    },
                  });
                  return;
                }

                if (onCall?.requester) {
                  makeRemoteUnavailableOnCallRequester().unavailable({
                    requesterId: Number(onCall?.requester),
                  });

                  History.getHistory().push('/duty/me', {
                    specialty: 'cardiologia',
                    healthUnitId: healthUnit?.id,
                    healthUnitName: healthUnit?.name,
                    cnes: healthUnit?.cnes,
                    city: healthUnit?.city,
                  });

                  if (outcomeByRequester)
                    storeDev.dispatch({
                      type: AppointmentTypes.STOP,
                      payload: {
                        id: Number(appointmentId),
                        onCall: {
                          id: onCall?.id,
                          specialty: onCall?.name,
                          leaveConf: false,
                        },
                      },
                    });
                  return;
                }

                History.getHistory().replace('/appointment', {
                  appointmentId,
                });

                if (!onCall?.sessionName)
                  storeDev.dispatch({
                    type: AppointmentTypes.STOP,
                    payload: {
                      type: 'appointment',
                      id: Number(appointmentId),
                    },
                  });
              },
              shareLink: link?.guest ?? undefined,
            });
          }
          setLoading(false);
        } catch (error) {
          console.log('>>>', error);
          setLoading(false);
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possível entrar na conferência, tente novamente',
            }),
            type: 'danger',
          });
          History.getHistory().replace('/appointment');
        }
      };

      start();

      // timeoutMeet.current = setTimeout(() => {
      //   const meetChildNodesCount =
      //     document.getElementById('meet')?.childNodes.length;

      //   if (!meetChildNodesCount) start();
      // }, 2000);
    }

    return () => {
      if (timeoutMeet?.current) clearTimeout(timeoutMeet.current);
      if (appointmentId && waitingRoomUserId)
        makeReduxLeftWaitingRoom().left({
          appointmentId: Number(appointmentId),
          waitingRoomUserId: Number(waitingRoomUserId),
        });
    };
  }, [
    appointmentId,
    auth.user?.firstName,
    auth.user?.lastName,
    auth.user?.mainRole,
    role,
    guestData,
    timeoutMeet,
    waitingRoomUserId,
  ]);

  return (
    <Container>
      {!loading && <Sidebar />}
      <MeetDiv id="meet">{loading && <V4hSpin fullScreen />}</MeetDiv>
    </Container>
  );
};
