import React from 'react';
import { Container, Content, Footer, ContainerModal, Text } from './styles';
import { Icon, Typography, Button } from '@wisecare-tech/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';

interface ownProps {
  message: iMessage;
}

const StartInterconsult: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.startInterconsultMedicalOpinion;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#E6F0FB',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="info" color="blue-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Iniciar interconsulta
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Você agora está aceitando essa solicitação como uma
                  videoconferência e o solicitante receberá uma notificação para
                  entrar na consulta com você. Tem certeza que deseja continuar?
                </Typography>
              </Text>
            </Content>
            <Footer>
              <Button variant="textDestructive" onClick={actionCancel}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={actionOk}>
                Sim, quero continuar
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default StartInterconsult;
