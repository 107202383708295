import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadFileDiagnosisReport as UsecaseRemoteDownloadFileDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
// import { DownloadFileDiagnosisReport as UsecaseReduxDownloadFileDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemoteDownloadFileDiagnosisReport } from '~/data/repository/diagnosisReport';
// import { ReduxDownloadFileDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemoteDownloadFileDiagnosisReport =
  (): UsecaseRemoteDownloadFileDiagnosisReport =>
    new RemoteDownloadFileDiagnosisReport(
      makeApiUrl('/reports/{report}/files/{file}/DOWNLOAD'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxDownloadFileDiagnosisReport =
  (): UsecaseReduxDownloadFileDiagnosisReport =>
    new ReduxDownloadFileDiagnosisReport(); */
