import { CreateDetachedClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteCreateDetachedClinicalDocs
  implements CreateDetachedClinicalDocs
{
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateDetachedClinicalDocs.Model>;

  constructor(
    url: string,
    httClient: HttpClient<CreateDetachedClinicalDocs.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateDetachedClinicalDocs.Params,
  ): Promise<CreateDetachedClinicalDocs.Model> => {
    console.log('Params create clinical docs: ', params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        appointment: params.appointment,
        content: params.content,
        // certificate: params.certificate,
        healthUnit: params.healthUnit,
        org: params.org,
        orgUnit: params.orgUnit,
        patient: params.patient,
        patientExternal: params.patientExternal,
        professional: params.professional,
        // signatureSource: params.signatureSource,
        specialty: params.specialty,
        type: params.type,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
