import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateDetachedClinicalDocs as UseCaseRemoteCreateDetachedClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';

import { RemoteCreateDetachedClinicalDocs } from '~/data/repository/clinicalDocs';

export const makeRemoteCreateDetachedClinicalDocs =
  (): UseCaseRemoteCreateDetachedClinicalDocs =>
    new RemoteCreateDetachedClinicalDocs(
      makeApiUrl('/v2/clinicalDocs/DETACHED'),
      makeHttpClient(),
    );
