import { Button, Icon, Typography } from '@wisecare-tech/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import React from 'react';
import { closeModal } from '~/utils/closeModal';
import {
  Container,
  ContainerModal,
  Content,
  Footer,
  Header,
} from './styles/StyledTwoButtonModal';

export interface propsTwoButtonModal {
  message: iMessage;
}

export const TwoButtonModal: React.FC<propsTwoButtonModal> = ({ message }) => {
  const msgName = MessageOptions.twoButtonModal;

  const { active, actionOk, data } = message;

  const selectVariantInfo = () => {
    if (data?.variant === 'CANCEL' && data?.exit) {
      return 'primary';
    }

    if (data?.variant === 'CANCEL') {
      return 'destructive';
    }

    return 'primary';
  };

  return (
    <>
      {msgName === active ? (
        <ContainerModal>
          <Container>
            <Content>
              <Header variant={data?.variant}>
                {data?.variant === 'INFO' ? (
                  <Icon name="info" size="h5" color="blue-7" />
                ) : (
                  <Icon name="warning" size="h5" color="orange-7" />
                )}
              </Header>
              <Typography variant="t1_18semibold">{data?.title}</Typography>
              <Typography variant="b1_16regular" style={{ color: '#656a6e' }}>
                {data?.message}
              </Typography>
            </Content>
            <Footer>
              <Button variant="textDestructive" onClick={closeModal}>
                {data?.textSecondaryButton}
              </Button>
              <Button
                variant={selectVariantInfo()}
                onClick={() => {
                  actionOk?.();
                  closeModal();
                }}
              >
                {data?.textPrimaryButton}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      ) : null}
    </>
  );
};
