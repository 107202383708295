import { UnavailableOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteUnavailableOnCallSpecialist
  implements UnavailableOnCallSpecialist
{
  private readonly url: string;

  private readonly httpClient: HttpClient<UnavailableOnCallSpecialist.Model>;

  constructor(
    url: string,
    httClient: HttpClient<UnavailableOnCallSpecialist.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  unavailable = async (
    params: UnavailableOnCallSpecialist.Params,
  ): Promise<UnavailableOnCallSpecialist.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url
        .replace('{onCallId}', params.onCallId.toString())
        .replace('{specialistId}', params.specialistId.toString()),
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
