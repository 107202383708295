import { columns } from './../RemoteReport/columns/index';
import { Info } from './../../roles/professional/components/cardInterConsulta/interface';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 184px);
  padding: 24px 72px;
  gap: 16px;
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 72px;
  height: 72px;
  border-top: 1px solid #dedede;
  background: #ffffff;

  div {
    height: 100%;
    display: flex;
    gap: 16px;
  }
`;

type columnsProps = {
  width: string;
};

export const Column = styled.div<columnsProps>`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;

  .name {
    text-transform: capitalize;
  }
`;

export const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 16px 24px;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #ffffff;

  .left {
    width: 60%;
  }

  .right {
    width: 40%;
  }
`;

export const DiagnosisType = styled.div`
  width: 40%;
  padding: 16px 24px;
  border: 1px solid #dedede;
  border-radius: 8px;
  font-weight: 500;
  background: #ffffff;

  .title {
    color: #656a6e;
  }
`;

export const DiagnoseContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #ffffff;

  .title {
    padding: 16px 24px;
    border-bottom: 1px solid #dedede;
    font-weight: 700;
  }

  .editor {
    height: calc(100% - 99px);
    padding: 16px 24px;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
