import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateAttachedClinicalDocs as UseCaseRemoteCreateAttachedClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';

import { RemoteCreateAttachedClinicalDocs } from '~/data/repository/clinicalDocs';

export const makeRemoteCreateAttachedClinicalDocs =
  (): UseCaseRemoteCreateAttachedClinicalDocs =>
    new RemoteCreateAttachedClinicalDocs(
      makeApiUrl('/v2/clinicalDocs/ATTACHED'),
      makeHttpClient(),
    );
