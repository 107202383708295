import React from 'react';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { iMessage } from '~/domain/interfaces/models';
import {
  Container,
  ContainerModal,
  Footer,
  Header,
  Info,
} from './styles/StyledWelcomeDutyModal';
import {
  IconButton,
  Typography,
  Button,
} from '@wisecare-tech/design-system-web';

interface propsModalWelcomeDuty {
  message: iMessage;
}

export const ModalWelcomeDuty: React.FC<propsModalWelcomeDuty> = ({
  message,
}) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.welcomeDuty;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <Info>
                <Typography variant="t1_18semibold">Seja bem-vindo!</Typography>
                <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
                  Você está entrando no plantão de {data?.name}.
                </Typography>
              </Info>
              <IconButton
                icon="close"
                variant="primary"
                size="md"
                onClick={actionCancel}
              />
            </Header>
            <Footer>
              <Button variant="primary" onClick={actionOk}>
                Entrar no plantão
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};
