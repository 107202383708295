import React, { useState } from 'react';
import {
  Container,
  Content,
  Footer,
  ContainerModal,
  Text,
  SelectContainer,
} from '../evaluateMedicalOpinion/styles';
import {
  Icon,
  Typography,
  Button,
  RadioGroup,
  RadioGroupItem,
} from '@wisecare-tech/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import { Select } from '~/presentation/components/UI';
import { makeRemotePreSignDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/PreSignDiagnosisReportFactory';
import {
  OptionContainer,
  Option,
  OptionContent,
  OptionDescription,
} from './styles';
import { makeRemoteStopAppointment } from '~/main/factories/usecases/appointment/StopAppointmentFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { Signature } from '~/presentation/components/signature';
import { makeRemotePresignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/PresignClinicalDocsDetachedFactory';

interface ownProps {
  message: iMessage;
}

const SignMedicalOpinion: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.signMedicalOpinion;

  const [signNow, setSignNow] = useState<boolean | undefined>(true);
  const [selectedOption, setSelectedOption] = useState<string>('1');
  const [signatureData, setSignatureData] = useState<{
    certificateId: string;
    certificate: string;
    signatureSource?: string;
  }>();
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const options = [
    { value: '1', label: 'Selecione seu assinador' },
    // TODO: Implementar ledger
    // { value: '2', label: 'Ledger' },
    { value: '3', label: 'VIDaaS' },
  ];

  const handleSubmit = () => {
    if (signNow)
      makeRemoteStopAppointment()
        .stop({
          id: data.id,
          outcome: data.outcome,
        })
        .catch(() => {
          AlertMessage({
            title: 'Erro',
            message: 'Erro ao finalizar o atendimento',
            type: 'danger',
          });
        });

    makeRemotePresignClinicalDocs()
      .presign({
        docId: data?.id,
        signatureSource: signatureData?.signatureSource ?? 'VIDAAS',
        certificate: signatureData?.certificate,
      })
      .then(res => {
        const url = res.dataToSign;
        localStorage.setItem('diagnosisReportId', data?.id);
        window.location.href = url;
      })
      .catch(err => {
        console.log('>>> Err 1: ', err);
      });

    closeModal();
  };

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Assinar o parecer
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Selecione seu assinador e depois seu certificado digital para
                  finalizar a assinatura do documento.
                </Typography>
              </Text>

              <Signature
                changeSelected={(e: {
                  certificateId: string;
                  certificate: string;
                  signatureSource?: string;
                }) =>
                  setSignatureData({
                    certificate: e.certificate,
                    certificateId: e.certificateId,
                    signatureSource: e.signatureSource,
                  })
                }
                changeDisabled={(state: boolean) => setDisableButton(state)}
                onSignSubmit={handleSubmit}
              />
              {/* 
              <RadioGroup
                onChange={event => {
                  console.log('teste', event);
                }}
              >
                <OptionContainer>
                  <Option>
                    <RadioGroupItem
                      value={'signNow'}
                      checked={signNow}
                      onClick={() => setSignNow(true)}
                    >
                      <Typography variant="t4_16medium">
                        Assinar agora
                      </Typography>
                    </RadioGroupItem>
                  </Option>
                  <OptionContent>
                    <SelectContainer>
                      <div className="select">
                        <strong>Assinador</strong>
                        <Select
                          value={selectedOption}
                          onChange={e => setSelectedOption(e.target.value)}
                          disabled={!signNow}
                        >
                          {options.map(item => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="select">
                        <strong>Certificado digital</strong>
                        <Select
                          disabled={
                            selectedOption === '1' || selectedOption === '3'
                          }
                        >
                          <option value="1">Selecione um certificado</option>
                        </Select>
                      </div>
                    </SelectContainer>
                  </OptionContent>
                </OptionContainer>
                <OptionContainer>
                  <Option>
                    <RadioGroupItem
                      checked={!signNow}
                      value={'signLater'}
                      onClick={() => setSignNow(false)}
                    >
                      <OptionDescription>
                        <Typography variant="t4_16medium">
                          Assinar depois
                        </Typography>
                        <Typography variant="b1_16regular">
                          O atendimento não será finalizado e seu parecer ficará
                          disponível para assinatura e desfecho posterior.
                        </Typography>
                      </OptionDescription>
                    </RadioGroupItem>
                  </Option>
                </OptionContainer>
              </RadioGroup> */}
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel && closeModal}>
                Cancelar{' '}
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={disableButton}
              >
                {signNow ? 'Assinar' : 'Concluir'}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default SignMedicalOpinion;
