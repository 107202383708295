import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';

import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { translator } from '~/presentation/components/i18n';
import { Button, Select } from '~/presentation/components/UI';
import { makeReduxSignClinicalDoc } from '~/main/factories/usecases/clinicalDocs/SignClinicalDocs';
import { MakeRemoteGetCertificates } from '~/main/factories/usecases/daemon/GetCertificates';
import { AlertMessage } from '../messages/AlertMessage';
import { V4hSpin } from '../spin';
import { makeRemoteGetVidasCertificate } from '~/main/factories/usecases/vidas/GetVidasCertificateFactory';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';

import {
  OptionContainer,
  Option,
  OptionContent,
  OptionDescription,
} from './styles/StyledSignature';
import { SelectContainer } from '../modals/teleconsultation/evaluateMedicalOpinion/styles';
import {
  RadioGroup,
  RadioGroupItem,
  Typography,
} from '@wisecare-tech/design-system-web';
import { GetCertificates } from '~/domain/usecases/deamon/remote';
import { iStore } from '~/domain/interfaces/models';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface OwnProps {
  changeSelected?: (data: any) => any;
  docId?: number;
  appointmentId?: number;
  changeDisabled?: (data: boolean) => any;
  onSignSubmit?: () => any;
  isSigned?: boolean;
}

interface iVidaasCertificate {
  slots?: {
    slot_alias?: string;
    label?: string;
  }[];
  status?: string;
}
const Signature: React.FC<OwnProps> = ({
  changeSelected,
  docId,
  appointmentId,
  changeDisabled,
  onSignSubmit,
  isSigned,
}): JSX.Element => {
  const options = [
    { value: '1', label: 'Selecione seu assinador' },
    { value: '2', label: 'Ledger' },
    { value: '3', label: 'VIDaaS' },
  ];

  const [certificates, setCertificates] = useState<GetCertificates.Model>([]);
  const [selected, setSelected] = useState<number>(-1);
  const [loadingCertificate, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [signNow, setSignNow] = useState<boolean>(!!docId);

  const [vidaasCertificates, setVidaasCertificates] = useState(
    {} as iVidaasCertificate,
  );

  const assign = useSelector((store: iStore) => store.clinicalDocs.loading);

  const professional = getProfessionalInfo();

  useEffect(() => {
    if (professional?.id) {
      makeRemoteGetVidasCertificate()
        .get({ professional: professional?.id })
        .then(res => setVidaasCertificates(res))
        .catch(e => console.log('error certificate', e));
    }
  }, [professional]);

  useEffect(() => {
    if (changeDisabled) changeDisabled(assign);
    setLoading(assign);
  }, [assign]);

  useEffect(() => {
    if (changeDisabled) {
      // * Se assinar agora estiver selecionado
      if (signNow) {
        // * Se um certificado estiver selecionado.
        if (selected !== -1) {
          changeDisabled(false);
        } else {
          changeDisabled(true);
        }
      } else {
        changeDisabled(false);
      }
    }
  }, [changeDisabled, signNow, selected]);

  useEffect(() => {
    if (!isSigned) {
      if (changeDisabled) changeDisabled(true);
      setLoading(true);
      MakeRemoteGetCertificates()
        .getCertificates()
        .then(response => {
          setCertificates(response);
          setLoading(false);
          if (changeDisabled) changeDisabled(false);
        })
        .catch(e => {
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possivel encontrar nenhum certificado',
            }),
            type: 'info',
          });
          setLoading(false);
          if (changeDisabled) changeDisabled(false);
        });
    }
  }, [isSigned]);

  const onSubmit = () => {
    makeReduxSignClinicalDoc().sign({
      certificate: certificates[selected]?.base64Certificate,
      certificateId: certificates[selected]?.id,
      docId,
      appointmentId,
    });
  };

  const textButton = translator('Assinar Documento');

  return (
    <RadioGroup
      onChange={event => {
        console.log('RadioGroup change:', event);
      }}
    >
      {!docId && (
        <OptionContainer>
          <Option>
            <RadioGroupItem
              value="signNow"
              checked={signNow}
              onClick={() => setSignNow(true)}
            >
              <Typography variant="t4_16medium">Assinar agora</Typography>
            </RadioGroupItem>
          </Option>
          <OptionContent>
            <SelectContainer>
              <div className="select">
                <strong>Assinador</strong>
                <Select
                  value={selectedOption}
                  onChange={e => {
                    setSelectedOption(e.target.value);
                    setSelected(-1);
                  }}
                  disabled={!signNow}
                >
                  {options.map(item => (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="select">
                <strong>Certificado digital</strong>
                <Select
                  value={selected}
                  disabled={
                    loadingCertificate || selectedOption === '1' || !signNow
                  }
                  onChange={e => {
                    setSelected(Number(e.target.value));
                    if (selectedOption === '3' && changeSelected) {
                      changeSelected({
                        signatureSource: 'VIDAAS',
                      });
                    }
                    if (selectedOption === '2') {
                      if (changeSelected)
                        changeSelected({
                          certificateId:
                            certificates[Number(e.target.value)].id,
                          certificate:
                            certificates[Number(e.target.value)]
                              .base64Certificate,
                        });
                    }
                  }}
                >
                  <option value="-1">Selecione um certificado</option>
                  {selectedOption === '2' &&
                    certificates.map((cert, index) => (
                      <option value={index} key={cert.id}>
                        {cert.name}
                      </option>
                    ))}

                  {selectedOption === '3' && (
                    <>
                      {vidaasCertificates?.slots?.map((e, index) => (
                        <option value={index}>{e?.label}</option>
                      ))}
                    </>
                  )}
                </Select>
              </div>
            </SelectContainer>
          </OptionContent>
        </OptionContainer>
      )}

      {!docId && (
        <OptionContainer>
          <Option>
            <RadioGroupItem
              value="signLater"
              checked={!signNow}
              onClick={() => setSignNow(false)}
            >
              <OptionDescription>
                <Typography variant="t4_16medium">Assinar depois</Typography>
                <Typography variant="b1_16regular">
                  O atendimento não será finalizado e seu parecer ficará
                  disponível para assinatura e desfecho posterior.
                </Typography>
              </OptionDescription>
            </RadioGroupItem>
          </Option>
        </OptionContainer>
      )}

      {docId ? (
        <div>
          {!loadingCertificate ? (
            <Button
              size="medium"
              rounded
              onClick={onSignSubmit}
              disabled={selected === -1}
            >
              {textButton}
            </Button>
          ) : (
            <V4hSpin fullScreen={false} size="40px" />
          )}
        </div>
      ) : (
        <div>
          {loadingCertificate && signNow && (
            <V4hSpin fullScreen={false} size="40px" />
          )}
        </div>
      )}
    </RadioGroup>
  );
};

export default Signature;
