import React, { useEffect, useState } from 'react';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  ContainerModal,
  Actions,
  Container,
  Content,
  DownloadFiles,
  File,
  FileName,
  Footer,
  Header,
  ListFiles,
  Titles,
  Right,
} from './styles/StyledSupportDocsModal';
import {
  Typography,
  IconButton,
  Button,
  InputSearch,
  Icon,
} from '@wisecare-tech/design-system-web';
import { makeRemoteListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { makeRemoteDownloadOnCallRequestFileById } from '~/main/factories/usecases/onCallRequestFile/DownloadOnCallRequestFileByIdFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { makeRemoteDownloadAllOnCallRequestFile } from '~/main/factories/usecases/onCallRequestFile/DownloadAllOnCallRequestFileFactory';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';

interface propsModalSupportDocs {
  message: iMessage;
}

interface iFiles {
  id: number;
  filename: string;
  mimetype: string;
  size: string;
  created: string;
}

interface iOnCallRequest {
  files: iFiles[];
  id: number;
  onCall: {
    id: number;
  };
}

export const SupportDocsModal: React.FC<propsModalSupportDocs> = ({
  message,
}) => {
  const { token } = useSelector((store: iStore) => store.auth.access);
  const [search, setSearch] = useState('');
  const [request, setRequest] = useState<iOnCallRequest>();

  const { active, actionOk, actionCancel, data } = message;

  const msgName = MessageOptions.supportDocs;

  let filesFiltered: iOnCallRequest['files'] = [];

  useEffect(() => {
    if (!data?.id) return;

    makeRemoteListOnCallRequest()
      .list({
        body: {
          filters: {
            id: Number(data?.id),
          },
        },
      })
      .then(response => {
        setRequest({
          files: response.records[0]?.files,
          id: response.records[0]?.id,
          onCall: {
            id: response.records[0]?.onCall?.id,
          },
        });
      })
      .catch(e => {
        console.log(e);
      });
  }, [data]);

  if (search && request?.files) {
    filesFiltered = request?.files?.filter(file =>
      file?.filename.toLowerCase().includes(search.toLowerCase()),
    );
  }

  const renderDocumentOfType = (mimeType: string) => {
    if (mimeType.includes('image')) {
      return <Icon name="image" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('application/pdf')) {
      return <Icon name="picture_as_pdf" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('audio')) {
      return <Icon name="audio_file" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('video')) {
      return <Icon name="video_file" color="blue-7" size="h3" />;
    }
    return null;
  };

  const downloadFileById = (
    onCallId: number,
    fileId: number,
    requestId: number,
  ) => {
    axios
      .get(
        makeApiUrl(
          `/oncall/${onCallId}/requests/${requestId}/files/${fileId}/DOWNLOAD`,
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            responseType: 'blob',
          },
        },
      )
      .then(response => {
        const link = document.createElement('a');
        link.href = response?.data?.url;
        link.download = response.data.filename;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(response?.data?.url);

        AlertMessage({
          message: 'Arquivo baixado com sucesso!',
          type: 'success',
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao baixar arquivo!',
          type: 'danger',
        });
      });
  };

  const viewDocuments = (
    onCallId: number,
    fileId: number,
    requestId: number,
  ) => {
    axios
      .get(
        makeApiUrl(
          `/oncall/${onCallId}/requests/${requestId}/files/${fileId}/DOWNLOAD`,
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        window.open(response?.data?.url, '_blank');

        AlertMessage({
          message: 'Arquivo baixado com sucesso!',
          type: 'success',
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao baixar arquivo!',
          type: 'danger',
        });
      });
  };

  const downloadAllFiles = (onCallId: number, requestId: number) => {
    if (!token) return;

    axios
      .get(
        makeApiUrl(
          `/oncall/${onCallId}/requests/${requestId}/files/DOWNLOAD?accessToken=${token}`,
        ),
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        const file = new Blob([response.data], {
          type: response.data.type,
        });

        const contentDisposition = response.headers['content-disposition'];
        const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);
        const fileName = fileNameMatch
          ? fileNameMatch[1]
          : `request-${requestId}.zip`;

        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileName;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(fileURL);

        AlertMessage({
          message: 'Arquivos baixados com sucesso!',
          type: 'success',
        });
      })
      .catch(e => {
        AlertMessage({
          message: 'Erro ao baixar todos os arquivos!',
          type: 'danger',
        });
      });
  };

  const renderFiles = (file: iFiles) => {
    return (
      <File key={file?.id}>
        <Right>
          {renderDocumentOfType(file?.mimetype)}
          <FileName>
            <Typography variant="b3_14medium">{file?.filename}</Typography>
            <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
              {file?.mimetype}
            </Typography>
          </FileName>
        </Right>
        <Actions>
          <IconButton
            icon="visibility"
            variant="primary"
            size="sm"
            onClick={() => {
              if (!request) return;
              viewDocuments(request?.onCall?.id, file?.id, request?.id);
            }}
          />
          <IconButton
            icon="download"
            variant="primary"
            size="sm"
            onClick={() => {
              if (!request) return;
              downloadFileById(request?.onCall?.id, file?.id, request?.id);
            }}
          />
        </Actions>
      </File>
    );
  };

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <Titles>
                <Typography variant="t1_18semibold">
                  Documentos de apoio
                </Typography>
                <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
                  Todos os arquivos foram adicionados pelos profissionais que
                  participaram desse atendimento.
                </Typography>
              </Titles>
              <IconButton
                icon="close"
                variant="primary"
                size="sm"
                onClick={actionCancel}
              />
            </Header>
            <Content>
              <DownloadFiles>
                <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
                  {`${request?.files?.length} arquivo(s)`}
                </Typography>
                <Button
                  variant="primary"
                  icon="download"
                  size="sm"
                  onClick={() => {
                    if (!request) return;
                    downloadAllFiles(request?.onCall?.id, request?.id);
                  }}
                >
                  Baixar arquivos
                </Button>
              </DownloadFiles>
              <InputSearch
                placeholder="Pesquisar"
                onChange={e => setSearch(e.target.value)}
              />

              <ListFiles>
                {filesFiltered?.length > 0 && search ? (
                  <>{filesFiltered?.map(file => renderFiles(file))}</>
                ) : (
                  <>{request?.files?.map(file => renderFiles(file))}</>
                )}
              </ListFiles>
            </Content>
            <Footer>
              <Button variant="primary" size="md" onClick={actionOk}>
                Entendi
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};
