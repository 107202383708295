import React from 'react';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Container, Content, ContainerModal, Text, Footer } from './styles';
import { Button, Icon, Typography } from '@wisecare-tech/design-system-web';

interface ownProps {
  message: iMessage;
}

const CancelMedicalOpinion: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.cancelMedicalOpinion;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#FFEFEA',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="warning" color="orange-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Cancelar parecer médico
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Se cancelar este parecer, a solicitação voltará para a
                  listagem e poderá ser aceita por outro profissional. Tem
                  certeza de que deseja continuar?
                </Typography>
              </Text>
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel}>
                Continuar escrevendo
              </Button>
              <Button variant="destructive" onClick={actionOk}>
                Sim, quero cancelar
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default CancelMedicalOpinion;
