import { Icon, Typography } from '@wisecare-tech/design-system-web';
import { useCallback } from 'react';
import { History } from '~/main/routes';
import { CreateRequest } from '~/presentation/components/createRequestForms';
import { Menu } from '~/presentation/components/menu';
import {
  Body,
  Container,
  LockScroll,
  Subheader,
  Back,
  Center,
  End,
} from './StyledCreateRequest';

const CreateRequestPage = () => {
  const goBack = useCallback(() => {
    History.getHistory().goBack();
  }, []);

  return (
    <Container>
      <LockScroll>
        <Menu />
        <Subheader>
          <Back onClick={goBack}>
            <Icon name="chevron_left" color="blue-7" />
            <Typography
              variant="b3_14medium"
              style={{
                color: '#0056b3',
              }}
            >
              Voltar para solicitações
            </Typography>
          </Back>
          <Center>
            <Typography
              variant="b3_14medium"
              style={{
                color: '#656a6e',
              }}
            >
              Nova solicitação de interconsulta
            </Typography>
          </Center>
          <End />
        </Subheader>
      </LockScroll>
      <Body>
        <CreateRequest />
      </Body>
    </Container>
  );
};

export default CreateRequestPage;
