import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 530px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #dedede;
`;

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid #dedede;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 24px;
`;
