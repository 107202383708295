import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadClinicalDocs as UsecaseRemoteDownloadClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
// import { DownloadClinicalDocs as UsecaseReduxDownloadClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteDownloadClinicalDocs } from '~/data/repository/clinicalDocs';
// import { ReduxDownloadClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';

/**
 * send request via API.
 */
export const makeRemoteDownloadClinicalDocs =
  (): UsecaseRemoteDownloadClinicalDocs =>
    new RemoteDownloadClinicalDocs(
      makeApiUrl('/clinicalDocs/{docId}/download'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxDownloadClinicalDocs =
  (): UsecaseReduxDownloadClinicalDocs =>
    new ReduxDownloadClinicalDocs(); */
