import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 50;
`;

export const Container = styled.div`
  min-width: 530px;
  max-width: 530px;
  height: fit-content;
  border-radius: 16px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  overflow-y: auto;
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 0px 24px;
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  /* padding: 16px 24px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  row-gap: 16px;
`;

export const ConsultInfoMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  row-gap: 8px;
  text-align: start;
  padding: 16px 24px 0px 24px;
`;

export const ConsultInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
  width: 100%;
  border-top: 1px solid #dedede;
`;

export const NewConsultInfo = styled.div`
  padding: 16px 24px;
`;

export const ScrollContainer = styled.div`
  display: grid;
  /* grid-gap: 10px; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  text-wrap: balance;
  padding: 0px 8px 12px 0px;
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid #dedede;
  background: #f8f8f8;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: #222529;
`;

export const TextBoldSmall = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #222529;
`;

export const TextBoldTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  display: flex;
  gap: 8px;
  font-size: 16px;
  color: #222529;
`;

export const TextNormalSubtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: #656a6e;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;
// End Text Components

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: 100%;
  font-size: 16px;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.div`
  font-weight: 500;
  width: 120px;
`;

export const Value = styled.div`
  text-align: left;
  flex: 1;
`;
