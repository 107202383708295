import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UnavailableOnCallSpecialist as UsecaseRemoteUnavailableOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
// import { UnavailableOnCallSpecialist as UsecaseReduxUnavailableOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemoteUnavailableOnCallSpecialist } from '~/data/repository/onCallSpecialist';
// import { ReduxUnavailableOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteUnavailableOnCallSpecialist =
  (): UsecaseRemoteUnavailableOnCallSpecialist =>
    new RemoteUnavailableOnCallSpecialist(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxUnavailableOnCallSpecialist =
  (): UsecaseReduxUnavailableOnCallSpecialist =>
    new ReduxUnavailableOnCallSpecialist(); */
