import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iSignClinicalDocs } from '~/domain/interfaces/redux/clinicalDocs/sign';

import { PresignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { Sign } from '~/domain/usecases/deamon/remote';
import { SignClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/SignClinicalDocs';

import {
  SignFailed,
  SignSuccess,
} from '~/data/store/reducer/clinicalDocs/actions/sign';

import { makeRemoteUpdateClinicalDocsBySign } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsBySignFactory';
import { makeRemoteSignDocument } from '~/main/factories/usecases/daemon/Sign';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeRemotePresignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/PresignClinicalDocsDetachedFactory';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onSign(action: iSignClinicalDocs) {
  try {
    yield SignDoc(action.payload);
  } catch (e) {
    yield put(SignFailed());
  }
}

export function onSignSuccess() {
  AlertMessage({
    type: 'success',
    message: intl.formatMessage({ id: 'Documento assinado com sucesso!' }),
  });
}

export function onSignFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao assinar documento clínico.' }),
  });
}

function* SignDoc(payload: SignClinicalDocs.Params) {
  const remoteSignClinicalDocs = makeRemoteSignDocument();
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  if (!payload.docId || !payload.appointmentId) throw new Error();

  const responsePresignDoc: PresignClinicalDocs.Model = yield call(
    getDocDelay,
    {
      docId: payload.docId,
      certificate: payload.certificate,
      signatureSource: payload.isVidaas ? 'VIDAAS' : 'LEDGERSIGN',
    },
  );

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    {
      certId: payload.certificateId,
      algorithm: 'SHA256',
      dataToSignArray: [
        {
          dataToSign: responsePresignDoc.dataToSign,
          key: 1,
        },
      ],
    },
  );

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: responseSignDocument[0].signature,
  });

  AlertMessage({
    message: intl.formatMessage({ id: 'Documento assinado com sucesso!' }),
    type: 'success',
  });
}

async function getDocDelay(
  data: PresignClinicalDocs.Params,
): Promise<PresignClinicalDocs.Model> {
  const remotePresignClinicalDocs = makeRemotePresignClinicalDocs();

  const response: PresignClinicalDocs.Model = await new Promise(
    (resolve, reject) => {
      setTimeout(async () => {
        remotePresignClinicalDocs
          .presign(data)
          .then(result => resolve(result))
          .catch(e => reject(e));
      }, 6000);
    },
  );

  return response;
}
