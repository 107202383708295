/* eslint-disable react/react-in-jsx-scope */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { History } from '~/main/routes';
import { IconDuties } from '~/presentation/base/icons';
import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import DutyPage from '~/presentation/pages/Duty';
import { closeModal } from '~/utils/closeModal';
import { makeReduxActiveMessage } from '../../usecases/message/Update';
import { makeReduxSetSpecialistStatusOnCall } from '../../usecases/onCall/SetSpecialistStatusOnCallFactory';
import { makeReduxListOnCallRequester } from '../../usecases/onCallRequester/ListOnCallRequesterFactory';
import { makeReduxListOnCallSpecialist } from '../../usecases/onCallSpecialist/ListOnCallSpecialistFactory';
import { ButtonToOpenSideSheet, Container } from './styles';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { makeRemoteUnavailableOnCallSpecialist } from '../../usecases/onCallSpecialist/UnavailableOnCallSpecialistFactory';

const DutyFactory: React.FC = () => {
  const professional = getProfessionalInfo();

  const { state } = useLocation<{
    specialty: string;
    id: string;
    active: MessageOptions;
  }>();

  const { specialty, id } = state;

  const handleLoadDutyRequesters = useCallback(() => {
    /*  makeReduxListOnCallRequest().list({
      body: { filters: { onCall: Number(id) } },
    }); */

    makeReduxListOnCallRequester().list({ onCallId: Number(id) });
    makeReduxListOnCallSpecialist().list({ onCallId: Number(id) });

    /* if (professional)
      makeRemoteJoinSpecialistOnCall().joinSpecialist({
        onCallId: Number(id),
        specialistId: professional.id,
      }); */
  }, []);

  useEffect(() => {
    handleLoadDutyRequesters();
  }, [handleLoadDutyRequesters]);

  useEffect(() => {
    makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
      available: false,
    });

    if (professional)
      makeRemoteUnavailableOnCallSpecialist()
        .unavailable({
          onCallId: Number(state?.id),
          specialistId: professional?.id,
        })
        .catch(err => {
          console.log('Error pausing: ', err);
        });
  }, []);

  return (
    <Container>
      <Menu />
      <PageHeader
        title={`Plantão de ${specialty}`}
        backDescription="Escolha uma especialidade"
        buttonActionRight={<Button onCallId={Number(state?.id)} />}
        onBack={() => {
          makeReduxActiveMessage().active({
            active: 'twoButtonModal',
            actionOk: () => {
              if (professional?.id)
                makeRemoteUnavailableOnCallSpecialist().unavailable({
                  onCallId: Number(id),
                  specialistId: professional.id,
                });
              History.getHistory().push('/duty');
              closeModal();
            },
            actionCancel: closeModal,
            data: {
              title: 'Confirme se quer sair do plantão',
              message:
                'Se sair agora, os profissionais não poderão atender à sua solicitação e você não receberá notificações sobre suas solicitações. Tem certeza que deseja sair agora?',
              textPrimaryButton: 'Sim, quero sair',
              textSecondaryButton: 'Permanecer aqui',
              variant: 'CANCEL',
              specialty,
              type: 'specialist',
              exit: true,
            },
          });
        }}
      />
      <DutyPage />
    </Container>
  );
};

const Button: React.FC<{ onCallId: number }> = ({ onCallId }) => {
  const handleOpenDrawer = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.professionalsOnDuty,
      data: {
        onCallId,
      },
    });
  }, [onCallId]);

  return (
    <ButtonToOpenSideSheet type="button" onClick={handleOpenDrawer}>
      <IconDuties />
    </ButtonToOpenSideSheet>
  );
};

export default DutyFactory;
