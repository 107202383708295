import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UnavailableOnCallRequester as UsecaseRemoteUnavailableOnCallRequester } from '~/domain/usecases/onCallRequester/remote';
// import { UnavailableOnCallRequester as UsecaseReduxUnavailableOnCallRequester } from '~/domain/usecases/onCallRequester/redux';

import { RemoteUnavailableOnCallRequester } from '~/data/repository/onCallRequester';
// import { ReduxUnavailableOnCallRequester } from '~/data/store/reducer/onCallRequester/usecases';

/**
 * send request via API.
 */
export const makeRemoteUnavailableOnCallRequester =
  (): UsecaseRemoteUnavailableOnCallRequester =>
    new RemoteUnavailableOnCallRequester(
      makeApiUrl('/oncall/requesters/{requesterId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxUnavailableOnCallRequester =
  (): UsecaseReduxUnavailableOnCallRequester =>
    new ReduxUnavailableOnCallRequester(); */
