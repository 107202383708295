import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ReportOnCallRequest as UsecaseRemoteReportOnCallRequest } from '~/domain/usecases/onCallRequest/remote';
// import { ReportOnCallRequest as UsecaseReduxReportOnCallRequest } from '~/domain/usecases/onCallRequest/redux';

import { RemoteReportOnCallRequest } from '~/data/repository/onCallRequest';
// import { ReduxReportOnCallRequest } from '~/data/store/reducer/onCallRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteReportOnCallRequest =
  (): UsecaseRemoteReportOnCallRequest =>
    new RemoteReportOnCallRequest(
      makeApiUrl(
        '/oncall/{onCallId}/specialist/{specialistId}/requests/{requestId}/REPORT',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxReportOnCallRequest =
  (): UsecaseReduxReportOnCallRequest =>
    new ReduxReportOnCallRequest(); */
