import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PresignClinicalDocs as UseCaseRemotePresignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';

import { RemotePresignClinicalDocs } from '~/data/repository/clinicalDocs';

export const makeRemotePresignClinicalDocs =
  (): UseCaseRemotePresignClinicalDocs =>
    new RemotePresignClinicalDocs(
      makeApiUrl('/v2/clinicalDocs/{docId}/presign'),
      makeHttpClient(),
    );
