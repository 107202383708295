import React, { useCallback } from 'react';
import { Action, Container, Item, List, Actions, Info } from './styles';
import { Button, Input } from '~/presentation/components/UI';
import { IconSearch } from '~/presentation/base/icons';
import { ListDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import saveAs from 'file-saver';
import { iStore } from '~/domain/interfaces/models';
import { useSelector } from 'react-redux';
import { Icon } from '@wisecare-tech/design-system-web';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

interface ownProps {
  id: number;
  files?: ListDiagnosisReport.Model['records'][0]['files'];
  downloadFile: (id: number, fileId: number) => Promise<any>;
}

const ListDiagnosisFiles: React.FC<ownProps> = ({
  id,
  files,
  downloadFile,
}) => {
  const listFiles = useCallback(() => {}, [files]);

  const viewFile = (file: number) => {
    downloadFile(id, file)
      .then(response => {
        const file = new Blob([response.data], {
          type: response.data.type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(error => {
        AlertMessage({
          message: 'Erro ao abrir arquivo',
          type: 'danger',
        });
      });
  };

  const download = (file: number, name: string) => {
    downloadFile(id, file)
      .then(response => {
        saveAs(response.data, name);
      })
      .catch(error => {
        AlertMessage({
          message: 'Erro ao abrir arquivo',
          type: 'danger',
        });
      });
  };

  return (
    <Container>
      <div className="info-row">
        <div>{`${files?.length} Arquivo${files?.length !== 1 ? 's' : ''}`}</div>
        <Button size="medium">Baixar arquivos</Button>
      </div>
      <Input placeholder="Buscar arquivo" icon={IconSearch} />
      <List>
        {files?.map((file, index) => (
          <Item>
            <Info>
              <strong>{file.filename}</strong>
              <div>{file.examType || 'Não informado'}</div>
            </Info>
            <Actions>
              <Action
                onClick={() => {
                  viewFile(file.id);
                }}
              >
                <Icon name="visibility" size="small" color="black-1" />
              </Action>
              <Action
                onClick={() => {
                  download(file.id, file.filename);
                }}
              >
                <Icon name="download" size="small" color="black-1" />
              </Action>
            </Actions>
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default ListDiagnosisFiles;
