import React, { useEffect, useState } from 'react';
import {
  Container,
  Content,
  Footer,
  ContainerModal,
  Text,
  RadioContainer,
} from './styles';
import { Icon, Typography, Button } from '@wisecare-tech/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import { makeRemoteEvaluateDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/EvaluateDiagnosisReportFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iAppointmentOutcome } from '~/domain/usecases/appointment/remote';
import { makeRemoteGetAppointmentOutcome } from '~/main/factories/usecases/appointment/GetAppointmentOutcomeFactory';
import { makeRemoteStopAppointment } from '~/main/factories/usecases/appointment/StopAppointmentFactory';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { makeRemoteReportOnCallRequest } from '~/main/factories/usecases/onCallRequest/ReportOnCallRequestFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';

type Outcome =
  | 'PERMANENCE'
  | 'REFERRAL'
  | 'RETURN'
  | 'DEATH'
  | 'CLINICAL_DISCHARGE'
  | 'TRANSFER';

interface ownProps {
  message: iMessage;
}

const EvaluateMedicalOpinion: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;
  const [appointmentOutcomes, setAppointmentOutcomes] = useState<
    iAppointmentOutcome[]
  >([]);

  const [selectedOption, setSelectedOption] = useState<number | undefined>();

  const professional = getProfessionalInfo();

  const msgName = MessageOptions.evaluateMedicalOpinion;

  useEffect(() => {
    if (msgName === active) {
      console.log('>>> data leave interconsult', data.onCall);
      makeRemoteGetAppointmentOutcome()
        .getRecordings({})
        .then(response => {
          setAppointmentOutcomes(response);
          console.log('>>> appointmentOutcomes', appointmentOutcomes);
        });
    }
  }, [active]);

  const handleSubmit = () => {
    if (!selectedOption) {
      AlertMessage({
        title: 'Dados obrigatórios',
        message: 'Selecione uma opção para prosseguir',
        type: 'danger',
      });
      return;
    }

    if (professional)
      makeReduxActiveMessage().active({
        active: 'signMedicalOpinion',
        actionCancel: closeModal,
        data: {
          id: data.appointmendtId,
          docId: data?.docId,
          outcome: selectedOption,
        },
      });
  };

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#E6F0FB',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="info" color="blue-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Selecione o desfecho
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Antes de assinar, é necessário selecionar o desfecho desta
                  solicitação.
                </Typography>
              </Text>
              {appointmentOutcomes.map(outcome => (
                <RadioContainer>
                  <input
                    type="radio"
                    name={`option-${outcome.id}`}
                    id={`option-${outcome.id}`}
                    value={outcome.id}
                    checked={selectedOption === outcome.id}
                    onChange={() => setSelectedOption(outcome.id)}
                  />
                  <div>
                    <strong>{outcome.name} </strong>({outcome.descr})
                  </div>
                </RadioContainer>
              ))}
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel && closeModal}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!selectedOption}
              >
                Ir para assinatura
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default EvaluateMedicalOpinion;
