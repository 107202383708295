import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AssignOnCallRequest as UsecaseRemoteAssignOnCallRequest } from '~/domain/usecases/onCallRequest/remote';
// import { AssignOnCallRequest as UsecaseReduxAssignOnCallRequest } from '~/domain/usecases/onCallRequest/redux';

import { RemoteAssignOnCallRequest } from '~/data/repository/onCallRequest';
// import { ReduxAssignOnCallRequest } from '~/data/store/reducer/onCallRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteAssignOnCallRequest =
  (): UsecaseRemoteAssignOnCallRequest =>
    new RemoteAssignOnCallRequest(
      makeApiUrl('/oncall/{onCallId}/specialist/{specialistId}/requests/{requestId}/ASSIGN'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxAssignOnCallRequest =
  (): UsecaseReduxAssignOnCallRequest =>
    new ReduxAssignOnCallRequest(); */
