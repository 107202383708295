import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 530px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #dedede;
`;

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
`;

export const Content = styled.div`
  display: flex;
  padding: 16px 24px;
  gap: 16px;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 5px;
  border: 1px #dedede solid;

  cursor: pointer;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Professional = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px 16px 0;
  align-items: center;
`;

interface PriorityProps {
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW';
}

export const priorityColorStyle = (
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW',
) => {
  switch (priority) {
    case 'EMERGENCY':
      return css`
        svg path {
          fill: #dd0404;
        }
      `;

    case 'NORMAL':
      return css`
        svg path {
          fill: #fe632f;
        }
      `;

    case 'LOW':
      return css`
        svg path {
          fill: #458108;
        }
      `;

    default:
      return css`
        svg path {
          fill: #458108;
        }
      `;
  }
};

export const Priority = styled.div<PriorityProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ priority }) => priorityColorStyle(priority)}
`;
