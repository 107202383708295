import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AvailableOnCallSpecialist as UsecaseRemoteAvailableOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
// import { AvailableOnCallSpecialist as UsecaseReduxAvailableOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemoteAvailableOnCallSpecialist } from '~/data/repository/onCallSpecialist';
// import { ReduxAvailableOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteAvailableOnCallSpecialist =
  (): UsecaseRemoteAvailableOnCallSpecialist =>
    new RemoteAvailableOnCallSpecialist(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxAvailableOnCallSpecialist =
  (): UsecaseReduxAvailableOnCallSpecialist =>
    new ReduxAvailableOnCallSpecialist(); */
