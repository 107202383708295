import styled from 'styled-components';

export const Container = styled.div``;

export const MainText = styled.div`
  margin-top: 24px;

  //styleName: b3_14medium;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-decoration-skip-ink: none;
  color: #222529;
`;

export const SubText = styled.div`
  margin-top: 8px;

  //styleName: b4_14regular;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #656a6e;
`;
