import { ReactivateOnCallRequest } from '~/domain/usecases/onCallRequest/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  Conflict,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteReactivateOnCallRequest implements ReactivateOnCallRequest {
  private readonly url: string;

  private readonly httpClient: HttpClient<ReactivateOnCallRequest.Model>;

  constructor(
    url: string,
    httClient: HttpClient<ReactivateOnCallRequest.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  reactivate = async (
    params: ReactivateOnCallRequest.Params,
  ): Promise<ReactivateOnCallRequest.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.onCallId}/requesters/${params.requesterId}/requests/${params.requestId}/REACTIVATE`,
      body: params.body,
      method: 'patch',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
