import { Icon } from '@wisecare-tech/design-system-web';
import React from 'react';
import { MainText, SubText } from './styles';

const EmptyStateTable: React.FC = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 64,
          width: 64,
          borderRadius: 100,
          border: '1px solid #dedede',
        }}
      >
        <Icon name="file_present" color="white-7" size="h6" />
      </div>
      <MainText>Nenhum arquivo adicionado</MainText>
      <SubText>
        Você pode adicionar arquivos de apoio para essa solicitação.
      </SubText>
    </>
  );
};

export default EmptyStateTable;
