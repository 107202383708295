import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AvailableOnCallRequester as UsecaseRemoteAvailableOnCallRequester } from '~/domain/usecases/onCallRequester/remote';
// import { AvailableOnCallRequester as UsecaseReduxAvailableOnCallRequester } from '~/domain/usecases/onCallRequester/redux';

import { RemoteAvailableOnCallRequester } from '~/data/repository/onCallRequester';
// import { ReduxAvailableOnCallRequester } from '~/data/store/reducer/onCallRequester/usecases';

/**
 * send request via API.
 */
export const makeRemoteAvailableOnCallRequester =
  (): UsecaseRemoteAvailableOnCallRequester =>
    new RemoteAvailableOnCallRequester(
      makeApiUrl('/oncall/requesters/{requesterId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxAvailableOnCallRequester =
  (): UsecaseReduxAvailableOnCallRequester =>
    new ReduxAvailableOnCallRequester(); */
