import { ColumnDef } from '@tanstack/react-table';
import {
  IconButton,
  SortButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import {
  DiagnosisReportListRecords,
  Status,
} from '~/domain/usecases/diagnosisReport/remote';
import { IconFlag } from '~/presentation/base/icons';
import { Popover } from '~/presentation/components/UI/popover';
import { priorityMap } from '~/utils/mapPriority';
import {
  calculateAgeLaudo,
  formatISODateTimeLaudo,
} from '~/utils/tableDateLaudo';
import { Information, Priority } from './styles';
import { findLastOccurrence } from '~/utils/laudoLastOccurrence';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { iOnCallRequestRecords } from '~/domain/usecases/onCallRequest/remote';

export const columns_clinicalopnion: ColumnDef<iOnCallRequestRecords>[] = [
  {
    accessorKey: 'consultant',
    header: ({ column }) => <SortButton column={column}>Paciente</SortButton>,
    cell: ({ row }) => {
      const data = row.getValue(
        'consultant',
      ) as iOnCallRequest['records'][0]['consultant'];

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {calculateAgeLaudo(data?.birthdate)}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'onCall',
    header: ({ column }) => {
      return <SortButton column={column}>Especialidade</SortButton>;
    },
    cell: ({ row }) => {
      const onCall = row.getValue(
        'onCall',
      ) as iOnCallRequest['records'][0]['onCall'];

      return <Typography variant="b4_14regular">{onCall.name}</Typography>;
    },
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <SortButton column={column}>Prioridade</SortButton>,
    cell: ({ row }) => {
      const priority = row.getValue(
        'priority',
      ) as iOnCallRequest['records'][0]['priority'];

      return (
        <Priority priority={priority}>
          <IconFlag />
          {priorityMap(priority)}
        </Priority>
      );
    },
  },
  {
    accessorKey: 'created',
    header: ({ column }) => (
      <SortButton column={column}>Solicitado em</SortButton>
    ),
    cell: ({ row }) => {
      const created = row.getValue(
        'created',
      ) as iOnCallRequest['records'][0]['created'];

      function formatDate(dateString: string): { date: string; hour: string } {
        const date = parseISO(dateString);

        return {
          date: format(date, 'dd/MM/yyyy', { locale: ptBR }),
          hour: format(date, 'HH:mm', { locale: ptBR }),
        };
      }

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatDate(created).date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {formatDate(created).hour}
          </Typography>
        </Information>
      );
    },
  },
  // {
  //   id: 'actions',
  //   enableHiding: true,
  //   cell: ({ row }) => {
  //     const value = row.original;

  //     // const professional_id = getProfessionalInfo();

  //     // const handleGoToDetails = (id: number, edit = false) => {
  //     //   if (edit) {
  //     //     history.push(`/diagnosis/${id}`, {
  //     //       edit,
  //     //     });
  //     //     return;
  //     //   }

  //     //   history.push(`/diagnosis/${id}`);
  //     // };

  //     const store: iStore = storeDev.getState();
  //     const { role } = store.auth.selectUser;

  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           gap: 8,
  //         }}
  //       >
  //         <IconButton icon="edit" variant="primary" size="sm" />
  //       </div>
  //     );
  //   },
  // },
];
