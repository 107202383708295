import React, { useEffect, useState } from 'react';
import {
  Body,
  Column,
  Container,
  DiagnoseContainer,
  DiagnosisType,
  Footer,
  InfoContainer,
  PatientInfo,
  Row,
} from './styles';
import Editor from '~/presentation/components/editor';
import { Menu } from '~/presentation/components/menu';
import { Button, Textarea } from '@wisecare-tech/design-system-web';
import consultant from '~/data/store/saga/consultant';
import { makeRemoteEvaluateDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/EvaluateDiagnosisReportFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { HeaderSimples } from '~/presentation/components/header';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { closeModal } from '~/utils/closeModal';
import { calculateAgeLaudo } from '~/utils/tableDateLaudo';
import InfoColapse from '../Diagnose/InfoColapse';
import ListDiagnosisFiles from '../Diagnose/ListDiagnosisFiles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ListDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import { iStore } from '~/domain/interfaces/models';
import { ListOnCallRequest } from '~/domain/usecases/onCallRequest/redux';
import { makeRemoteReportOnCallRequest } from '~/main/factories/usecases/onCallRequest/ReportOnCallRequestFactory';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { makeRemoteAcceptOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AcceptOnCallSpecialistFactory';
import { History } from '~/main/routes';
import { makeRemoteAssignOnCallRequest } from '~/main/factories/usecases/onCallRequest/AssignOnCallRequestFactory';
import { makeRemoteJoinOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/JoinOnCallSpecialistFactory';
import { formatCpfToSpecialChars } from '~/utils/formatCpfToSpecialChars';
import { makeRemoteDownloadFileDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/DownloadFileDiagnosisReportFactory';
import { makeRemoteDownloadOnCallRequestFileById } from '~/main/factories/usecases/onCallRequestFile/DownloadOnCallRequestFileByIdFactory';

interface ownProps {
  data: ListOnCallRequest.Model['records'][0] | undefined;
  edit: boolean;
  opinion?: string;
}

const NewReport: React.FC<ownProps> = ({ data, edit, opinion }) => {
  const [openPendings, setOpenPendings] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [notes, setNotes] = useState('');
  const [evaluation, setEvaluation] = useState('');
  const [consultant, setConsultant] = useState<
    ListOnCallRequest.Model['records'][0]['consultant'] | undefined
  >(undefined);
  const professional = getProfessionalInfo();

  let { id, specialty } = useParams<{ id: string; specialty: string }>();

  useEffect(() => {
    data?.consultant &&
      setConsultant({
        ...data?.consultant,
        sex: data?.consultant.sex === 'MALE' ? 'M' : 'F',
      });

    console.log('>>> opinion', opinion, edit);
    if (edit) {
      setEditorValue(opinion ?? '');
      setEvaluation(data?.currentAppointment?.clinicalCondition ?? '');
      setNotes(data?.currentAppointment?.forwarding ?? '');
    }
  }, [data, edit, opinion]);

  return (
    <Container>
      <Menu />
      <HeaderSimples title="Parecer Médico" />

      <Body>
        <Column width="60%">
          <PatientInfo>
            <Row>
              <div className="name left">
                <strong>Nome:</strong>
                {` ${consultant?.fullname}`}
              </div>
              <div className="right">
                <strong>Sexo:</strong>{' '}
                {`${consultant?.sex === 'M' ? 'Masculino' : 'Feminino'}`}
              </div>
            </Row>
            <Row className="justify">
              <div className="left">
                <strong>Data de nascimento: </strong>
                {calculateAgeLaudo(consultant?.birthdate ?? '')}
              </div>
              {consultant?.docType === 'CPF' ? (
                <div className="right">
                  <strong>{consultant?.docType}: </strong>
                  {formatCpfToSpecialChars(consultant?.docValue)}
                </div>
              ) : (
                <div className="right">
                  <strong>{consultant?.docType} </strong> {consultant?.docValue}
                </div>
              )}
            </Row>
          </PatientInfo>
          <DiagnoseContainer>
            <div className="title">Parecer</div>
            <div className="editor">
              <Editor value={editorValue} onChange={setEditorValue} />
            </div>
          </DiagnoseContainer>
        </Column>
        <Column width="40%">
          <InfoContainer>
            <InfoColapse
              title="Subjetivo e Objetivo"
              children={<div>{data?.description}</div>}
            ></InfoColapse>
            <InfoColapse
              title="Avaliação e Plano de Cuidado"
              children={
                <Textarea
                  onChange={e => setEvaluation(e.target.value)}
                  placeholder="Escreva aqui o plano de cuidado do paciente."
                  value={evaluation}
                />
              }
            ></InfoColapse>
            <InfoColapse
              title="Anotações"
              children={
                <Textarea
                  onChange={e => setNotes(e.target.value)}
                  placeholder="Escreva aqui anotações sobre o paciente."
                  value={notes}
                />
              }
            ></InfoColapse>

            {data && (
              <InfoColapse
                title="Arquivos"
                children={
                  <ListDiagnosisFiles
                    id={data.id}
                    files={data?.files}
                    downloadFile={(file: number, id: number) => {
                      return makeRemoteDownloadOnCallRequestFileById().download(
                        {
                          fileId: file,
                          requestId: id,
                          onCallId: Number(specialty),
                        },
                      );
                    }}
                  />
                }
              ></InfoColapse>
            )}
          </InfoContainer>
        </Column>
      </Body>
      <Footer>
        <div>
          <Button
            size="sm"
            variant="outlinedDestructive"
            onClick={() => {
              makeReduxActiveMessage().active({
                active: 'cancelMedicalOpinion',
                actionCancel: closeModal,
                actionOk: () => {
                  History.getHistory().goBack();
                  closeModal();
                },
              });
            }}
          >
            Cancelar
          </Button>
        </div>
        <div>
          <Button
            size="sm"
            variant="outlined"
            onClick={() => {
              makeReduxActiveMessage().active({
                active: 'startInterconsultMedicalOpinion',
                actionCancel: closeModal,
                actionOk: () => {
                  if (professional)
                    makeRemoteAcceptOnCallSpecialist()
                      .accept({
                        onCallId: Number(specialty),
                        specialistId: professional?.id,
                        requestId: Number(id),
                      })
                      .then(() => {
                        if (editorValue || evaluation || notes)
                          makeRemoteReportOnCallRequest().report({
                            onCallId: Number(specialty),
                            specialistId: professional?.id,
                            requestId: Number(id),
                            body: {
                              opinion: editorValue,
                              clinicalCondition: evaluation,
                              forwarding: notes,
                            },
                          });

                        makeRemoteJoinOnCallSpecialist()
                          .join({
                            onCallId: Number(specialty),
                            specialistId: professional?.id,
                            requestId: Number(id),
                          })
                          .then(response => {
                            History.getHistory().push('/conf', {
                              appointmentId: response.appointmentId,
                              onCall: {
                                sessionName: response.sessionName,
                                id: Number(specialty),
                                name: Number(specialty).toString(),
                                specialist: professional?.id,
                              },
                            });
                          })
                          .catch(() => {
                            AlertMessage({
                              message: 'Erro ao entrar na conf!',
                              type: 'danger',
                            });
                          });
                      })
                      .catch(() => {
                        AlertMessage({
                          message: 'Erro ao aceitar solicitação!',
                          type: 'danger',
                        });
                      });
                  closeModal();
                },
              });
            }}
          >
            Iniciar Interconsulta
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              /* console.log(
                Number(specialty),
                professional?.id,
                Number(id),
                editorValue,
              ); */
              if (professional)
                makeRemoteReportOnCallRequest()
                  .report({
                    onCallId: Number(specialty),
                    specialistId: professional?.id,
                    requestId: Number(id),
                    body: {
                      opinion: editorValue,
                      clinicalCondition: evaluation,
                      forwarding: notes,
                    },
                  })
                  .then(res => {
                    makeReduxActiveMessage().active({
                      active: 'evaluateMedicalOpinion',
                      actionCancel: closeModal,
                      data: {
                        appointmendtId:
                          data?.appointments[data?.appointments.length - 1].id,
                        docId: res.clinicalDoc,
                      },
                    });
                  })
                  .catch(() => {
                    AlertMessage({
                      title: 'Erro',
                      message: 'Erro ao concluir parecer',
                      type: 'danger',
                    });
                  });
            }}
          >
            Concluir e assinar parecer
          </Button>
        </div>
      </Footer>
    </Container>
  );
};

export default NewReport;
